const ACTION_TYPES ={
  LOGIN_REQUEST:'auth/loginRequest',
  SIGN_UP_REQUEST:'auth/signUpRequest',
  REFRESH_REQUEST:'auth/refreshRequest',
  CLEAR_USER_STORE: 'CLEAR_USER_STORE',
  AUTH_SUCCESS:'auth/success',
  AUTH_ERROR:'auth/error',
  AUTH_REFRESH_ERROR:'auth_refresh_error',

  CATEGORY_GET_REQUEST: 'CATEGORY_GET_REQUES',
  CATEGORY_GET_SUCCESS: 'CATEGORY_GET_SUCCESS',
  CATEGORY_GET_ERROR: 'CATEGORY_GET_ERROR',

  CATEGORY_GET_BY_SECTION_REQUEST: 'CATEGORY_GET_BY_SECTION_REQUES',
  CATEGORY_GET_BY_SECTION_SUCCESS: 'CATEGORY_GET_BY_SECTION_SUCCESS',
  CATEGORY_GET_BY_SECTION_ERROR: 'CATEGORY_GET_BY_SECTION_ERROR',

  CATEGORY_CREATE_REQUEST: 'CATEGORY_CREATE_REQUES',
  CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CATEGORY_CREATE_ERROR: 'CATEGORY_CREATE_ERROR',

  CATEGORY_DELETE_REQUEST: 'CATEGORY_DELETE_REQUES',
  CATEGORY_DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
  CATEGORY_DELETE_ERROR: 'CATEGORY_DELETE_ERROR',

  CATEGORY_UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUES',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  CATEGORY_UPDATE_ERROR: 'CATEGORY_UPDATE_ERROR',

  CATEGORY_GET_BY_ID_REQUEST: 'CATEGORY_GET_BY_ID_REQUES',
  CATEGORY_GET_BY_ID_SUCCESS: 'CATEGORY_GET_BY_ID_SUCCESS',
  CATEGORY_GET_BY_ID_ERROR: 'CATEGORY_GET_BY_ID_ERROR',

  SECTION_GET_REQUEST: 'SECTION_GET_REQUES',
  SECTION_GET_SUCCESS: 'SECTION_GET_SUCCESS',
  SECTION_GET_ERROR: 'SECTION_GET_ERROR',

  SECTION_CREATE_REQUEST: 'SECTION_CREATE_REQUES',
  SECTION_CREATE_SUCCESS: 'SECTION_CREATE_SUCCESS',
  SECTION_CREATE_ERROR: 'SECTION_CREATE_ERROR',

  SECTION_DELETE_REQUEST: 'SECTION_DELETE_REQUES',
  SECTION_DELETE_SUCCESS: 'SECTION_DELETE_SUCCESS',
  SECTION_DELETE_ERROR: 'SECTION_DELETE_ERROR',

  SECTION_UPDATE_REQUEST: 'SECTION_UPDATE_REQUES',
  SECTION_UPDATE_SUCCESS: 'SECTION_UPDATE_SUCCESS',
  SECTION_UPDATE_ERROR: 'SECTION_UPDATE_ERROR',

  PRODUCT_GET_REQUEST: 'PRODUCT_GET_REQUES',
  PRODUCT_GET_SUCCESS: 'PRODUCT_GET_SUCCESS',
  PRODUCT_GET_ERROR: 'PRODUCT_GET_ERROR',

  PRODUCT_GET_BY_CATEGORY_REQUEST: 'PRODUCT_GET_BY_CATEGORY_REQUES',
  PRODUCT_GET_BY_CATEGORY_SUCCESS: 'PRODUCT_GET_BY_CATEGORY_SUCCESS',
  PRODUCT_GET_BY_CATEGORY_ERROR: 'PRODUCT_GET_BY_CATEGORY_ERROR',

  PRODUCT_GET_BY_ID_REQUEST: 'PRODUCT_GET_BY_ID_REQUES',
  PRODUCT_GET_BY_ID_SUCCESS: 'PRODUCT_GET_BY_ID_SUCCESS',
  PRODUCT_GET_BY_ID_ERROR: 'PRODUCT_GET_BY_ID_ERROR',

  PRODUCT_CREATE_REQUEST: 'PRODUCT_CREATE_REQUES',
  PRODUCT_CREATE_SUCCESS: 'PRODUCT_CREATE_SUCCESS',
  PRODUCT_CREATE_ERROR: 'PRODUCT_CREATE_ERROR',

  PRODUCT_UPDATE_REQUEST: 'PRODUCT_UPDATE_REQUES',
  PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
  PRODUCT_UPDATE_ERROR: 'PRODUCT_UPDATE_ERROR',
  
  PRODUCT_LOCAL_UPDATE: 'PRODUCT_LOCAL_UPDATE',
  
  PRODUCT_DELETE_REQUEST: 'PRODUCT_DELETE_REQUES',
  PRODUCT_DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
  PRODUCT_DELETE_ERROR: 'PRODUCT_DELETE_ERROR',
  
  BASKET_CREATE: 'BASKET_CREATE',
 
  BASKET_CLEAR: 'BASKET_CLEAR',

  BASKET_MINUS: 'BASKET_MINUS',
  
  BASKET_PLUS: 'BASKET_PLUS',
  
  BASKET_DELETE: 'BASKET_DELETE',
  
  BASKET_SUM: 'BASKET_SUM',
 
  BUYER_CREATE: 'BUYER_CREATE',
  
  BUYER_LOCAL_UPDATE: 'BUYER_LOCAL_UPDATE',

  BUYERS_GET_REQUEST: 'BUYERS_GET_REQUES',
  BUYERS_GET_SUCCESS: 'BUYERS_GET_SUCCESS',
  BUYERS_GET_ERROR: 'BUYERS_GET_ERROR',

  BUYERS_DELETE_REQUEST: 'BUYERS_DELETE_REQUES',
  BUYERS_DELETE_SUCCESS: 'BUYERS_DELETE_SUCCESS',
  BUYERS_DELETE_ERROR: 'BUYERS_DELETE_ERROR',

  SLIDER_GET_REQUEST: 'SLIDER_GET_REQUES',
  SLIDER_GET_SUCCESS: 'SLIDER_GET_SUCCESS',
  SLIDER_GET_ERROR: 'SLIDER_GET_ERROR',

  NEXT_SLIDE_REQUEST: 'NEXT_SLIDE_REQUES',
  NEXT_SLIDE_SUCCESS: 'NEXT_SLIDE_SUCCESS',
  NEXT_SLIDE_ERROR: 'NEXT_SLIDE_ERROR',

  SLIDER_CREATE_REQUEST: 'SLIDER_CREATE_REQUEST',
  SLIDER_CREATE_SUCCESS: 'SLIDER_CREATE_SUCCESS',
  SLIDER_CREATE_ERROR: 'SLIDER_CREATE_ERROR',

  SLIDER_DELETE_REQUEST: 'SLIDER_DELETE_REQUEST',
  SLIDER_DELETE_SUCCESS: 'SLIDER_DELETE_SUCCESS',
  SLIDER_DELETE_ERROR: 'SLIDER_DELETE_ERROR',
  
  ACTIVE_MBILE_MENU: 'ACTIVE_MBILE_MENU',

  CHANGES_MBILE_MENU: 'CHANGES_MBILE_MENU',

  }
  
  export default ACTION_TYPES